import React, { useEffect, useState } from 'react'
import api, { API_URL } from '../../../../api'
import { useParams } from 'react-router-dom'
import LoadingIcon from '../../../loadingIcon'
import HumanReadableDate from '../../../formatedDateTime'
import Header from '../../../header'
import Footer from '../../../footer'
import '../../../../css/cohordApplications/cohordApplications.css'
import ExportExcel from '../../../exportExcel'
import CustomSelectInput from '../../../forms/customSelectInput'
const CohortApplications = () => {
    const { cohortId } = useParams()
    const [applications, setApplications] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [searchString, setSearchString] = useState("")
    // Fetch data from API
    useEffect(() => {
        const getApplications = async () => {
            try {
                const response = await api.get(`${API_URL}/trainings/cohorts/${cohortId}/applications/`)
                if (response.status === 200) {
                    setIsLoading(false)
                    setApplications(response.data)
                    console.log(response.data)
                }
            } catch (error) {
                setIsLoading(false)
                console.error(error)
            }
        }
        getApplications()
    }, [])
    return isLoading ? <LoadingIcon /> : (
        <div className='applications-placeholder'>
            <h3 className='title'>Applicants <span className="applications-count">{applications && applications.length}</span></h3>
            {
                <>
                    <div className="applications">
                        <div className="actions-filters">
                            <div className="search-input">
                                <input type="text" placeholder="Search by name, email, phone number" />
                                <button type="submit">Search</button>
                            </div>
                            <div className="filters">
                                <CustomSelectInput
                                    options={["Software Dev", 'Graphic Design']}
                                    selected={searchString}
                                    setSelected={setSearchString}
                                />
                                <CustomSelectInput options={["Name A-Z", "Date Applied"]} />
                                <ExportExcel data={applications} columnsToExport={['id', 'first_name']} />
                            </div>
                        </div>
                        {
                            applications && applications.length > 0
                                ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone number</th>
                                            <th>Gender</th>
                                            <th>Date applied</th>
                                            {/* <th>Training</th> */}
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {applications.map((application, index) => (
                                            <tr key={index}>
                                                <td>{application.user.first_name} {application.user.last_name} </td>
                                                <td>{application.user.email || '-'}</td>
                                                <td>{application.phone_number || '-'}</td>
                                                <td>{application.gender || '-'}</td>
                                                <td><HumanReadableDate date={application.date_created || '-'} /></td>
                                                <td>{application.status || '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                : 'No Application'
                        }
                    </div></>
            }
        </div>
    )
}


const CohortApplicationsPage = () => {
    return (
        <div className='applications-page'>
            <Header />
            <div className="container">
                <CohortApplications />
            </div>
            <Footer />
        </div>
    )
}
export default CohortApplicationsPage