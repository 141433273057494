import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/howtoapply/howtoapply.css';
import { useAuthentication } from "./contexts/authContext";
import ServiceForm from "./serviceForm";
import PaymentForm from "./forms/paymentForms";
import AuthPopup from "./forms/authPopup";
import LoadingIcon from "./loadingIcon";
import { API_URL } from '../api';

const Howtoapply = () => {
  const { isAuth } = useAuthentication();
  const [serviceNames, setServiceNames] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [serviceDetails, setServiceDetails] = useState({ name: '', price: 0 });
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const [isFormOpen, setIsFormOpen] = useState(true);

  const closeForm = () => {
      setIsFormOpen(false);
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${API_URL}/trainings/`);
        if (response.status === 200) {
        
          const publishedServices = response.data.filter(service => service.status === 'Published');
          const services = publishedServices.map(service => ({
            name: service.name,
            price: service.price
          }));
          setServiceNames(services);
          setIsLoading(false); 
        } else {
          throw new Error('Failed to fetch services');
        }
      } catch (error) {
        console.error('Error fetching services:', error);
        setIsLoading(false); 
      }
    };

    fetchServices();
  }, []);

  const toggleForm = (serviceName, servicePrice) => {
    if (showForm) {
      setShowForm(false);
    } else {
      setServiceDetails({ name: serviceName, price: servicePrice });
      setShowForm(true);
    }
  };

  return (
    <section className="how-to-apply" id='how-to-apply'>
      <div className="apply-content">
        <div className="text-header">
          <h2>HOW TO APPLY</h2>
        </div>

        <h1>
          To apply for a training program, <br />
          simply visit our website or contact us via email or phone
        </h1>

        <p>
          Our friendly staff will guide you through the application process and provide any assistance <br />
          you may need. Be sure to have the necessary information and documents ready, including <br />
          proof of eligibility.
        </p>
      </div>

      <div className='line'>
        <div className="registration-form">
          {isLoading ? (
            <LoadingIcon /> // Show loading indicator while fetching data
          ) : (
            serviceNames.map((service, index) => (
              <div className="registration-item" key={index}>
                <div className="regis-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30} color={"#07A1AF"} fill={"none"}>
                    <path d="M15 12C15 10.8954 15.8954 10 17 10C19.2091 10 21 11.7909 21 14C21 16.2091 19.2091 18 17 18C15.8954 18 15 17.1046 15 16V12Z" stroke="currentColor" strokeWidth="1.5" />
                    <path d="M9 12C9 10.8954 8.10457 10 7 10C4.79086 10 3 11.7909 3 14C3 16.2091 4.79086 18 7 18C8.10457 18 9 17.1046 9 16V12Z" stroke="currentColor" strokeWidth="1.5" />
                    <path d="M3 14V11C3 6.02944 7.02944 2 12 2C16.9706 2 21 6.02944 21 11V15.8462C21 17.8545 21 18.8586 20.6476 19.6417C20.2465 20.5329 19.5329 21.2465 18.6417 21.6476C17.8586 22 16.8545 22 14.8462 22H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <h1>{service.name}</h1>
                <div className='button-icon' onClick={() => toggleForm(service.name, service.price)}>
                  <h6>Apply Now</h6>
                  <div className="header-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                      <path d="M16.5 7.5L6 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M8 6.18791C8 6.18791 16.0479 5.50949 17.2692 6.73079C18.4906 7.95209 17.812 16 17.812 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            ))
          )}

          {showForm && isAuth ? (
            <ServiceForm PopupContent={
              <PaymentForm
                serviceName={serviceDetails.name}
                amount={serviceDetails.price}
                onClose={() => toggleForm()}
              />
            } />
          ) : showForm ? (
            <AuthPopup />
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default Howtoapply;

