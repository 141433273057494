import React from 'react'

const SlicedText = ({ text, limit }) => {
    if (text.length <= limit) {
        return text;
    }
    return text.slice(0, limit - 3) + '...';
}

export default SlicedText
