import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../components/header"
import Footer from "../components/footer"
import HeroSection from '../components/heroSection'
import { Link, useNavigate, } from 'react-router-dom'
import "../css/home/home.css"
import "../css/main/main.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import Howtoapply from '../components/howtoapply'
import { API_URL } from '../api';




const HomePage = () => {

  const [services, setServices] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate()


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${API_URL}/trainings/`);
        if (response.status === 200) {
          const publishedServices = response.data.filter(service => service.status === 'Published');
          const names = publishedServices.map(service => service.name);
          setServices(publishedServices);
        } else {
          throw new Error('Failed to fetch services');
        }
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    };

    fetchServices();
  }, []);

  const handleServiceClick = (serviceId) => {
    navigate(`/service/${encodeURIComponent(serviceId)}`);
  }



  return (

    <div>
      <Header />
      <HeroSection />

      <div className='page-text-img' id='about' >
        <div className="page-text">
          <div className='text-header'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
              <circle cx="5" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" />
              <circle cx="8" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" />
              <circle cx="17" cy="7" r="5" stroke="currentColor" strokeWidth="1.5" />
            </svg>
            <h2>
              ABOUT TRAINING CENTRE
            </h2>
          </div>

          <h1>
            Comprehensive <br />
            Staffing Resources Ltd (CSR) Training Center!
          </h1>

          <p>
            Nestled conveniently near our office, our training center serves as a dynamic hub for learning and development. Open six days a week, from 8 am to 9 pm, our center caters to a diverse array of individuals seeking to enhance their skills and knowledge.
          </p>


          <Link to={"/trainings/cohort/2/apply/"} className='button-icon' >
            <h6>
              Apply Now
            </h6>
            <div className="header-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                <path d="M16.5 7.5L6 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M8 6.18791C8 6.18791 16.0479 5.50949 17.2692 6.73079C18.4906 7.95209 17.812 16 17.812 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </Link>


        </div>

        <div className="page-img">
          <img src="/images/home/group-img.png" alt="" />
        </div>
      </div>



      <div className="watch-video">
        <div className='video'>
          <img src="/images/home/video.png" alt="" className='vid' />
          <div className="background-image"></div>
          <div>
            <div className="vid-icon">
              <i className="fa-solid fa-play"></i>
            </div>
            <div className='watch-vid'>
              <div>
                <h1>
                  Watch Our <br />
                  Video
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services" id='services'>
        <div className="service-starter">
          <div className='service-header'>
            <div className='text-header'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                <circle cx="5" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" />
                <circle cx="8" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" />
                <circle cx="17" cy="7" r="5" stroke="currentColor" strokeWidth="1.5" />
              </svg>
              <h2>
                TRAINING PROGRAMS
              </h2>
            </div>
            <h1>
              Our training programs cover <br />
              a wide range of areas
            </h1>
          </div>
          <p>
            Each program is carefully crafted to deliver practical skills and knowledge that are in high demand in today's job market. Participants can expect hands-on training, expert guidance, and personalized support throughout their learning journey.
          </p>
        </div>

        <div className='services-content'>
          {services.map((service) => (
            <div className='service-text' key={service.id} onClick={() => handleServiceClick(service.id)} style={{ cursor: 'pointer' }}>
              <div className='ser-icon'>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                  <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M2.5 9H21.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                  <path d="M6.99981 6H7.00879" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.9998 6H11.0088" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 13L9.22654 14.0572C9.74218 14.5016 10 14.7239 10 15C10 15.2761 9.74218 15.4984 9.22654 15.9428L8 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 17H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <p>{service.name}</p>
            </div>
          ))}
        </div>
      </div>








      <section className='training-center'>
        <div className='training-container'>
          <div className="training-header">
            <h5>AT CSR TRAINING CENTER</h5>
            <h1>We believe in empowering individuals <br />
              to reach their full potential</h1>
          </div>

          <div className="training-text">
            <div>
              <p>Whether you're a fresh graduate, a working
                professional, or a student on holiday, we aim to equip
                you with the tools and expertise
                needed to excel in <br /> today's competitive job market.</p>
              <br />
              <p>With a focus on languages, technical skills, and soft skills</p>
              <br />
              <p>Our training programs are designed to meet the
                evolving needs of both local and foreign companies.</p>

            </div>

            <div className="training-img">
              <img src="/images/home/training.png" alt="" />
            </div>
          </div>
        </div>

      </section>




      <section className='testimonial'>
        <div className="testimonial-header">
          <div className="text-header">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
              <circle cx="5" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" />
              <circle cx="8" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" />
              <circle cx="17" cy="7" r="5" stroke="currentColor" strokeWidth="1.5" />
            </svg>
            <h2>TESTIMONIALS</h2>
          </div>
          <h1><span>Hear What</span> They Say</h1>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          breakpoints={{
            360: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                  I enrolled in the English training program at CSR Training Center,
                  it was a game-changer for me. The instructors were highly
                  knowledgeable, and the interactive sessions helped me improve my
                  language skills significantly.
                </p>
              </div>
              <h2>Josian Ishimwe</h2>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                Enrolling in the software development training was a game-changer for my career.
                 The hands-on projects and expert guidance prepared me well for the industry.
                  I'm now equipped with the skills needed to excel in the tech world.
                </p>
              </div>
              <h2>Emily Uwase</h2>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                The accounting program provided by the CSR training center exceeded my expectations.
                 The instructors were knowledgeable and supportive, making complex financial concepts easy to understand.
                
                </p>
              </div>
              <h2>Jean Paul</h2>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                I'm grateful for the digital marketing course at thie CSR training center. 
                It covered everything from SEO to social media strategies in a practical and engaging way. 
                Thanks to this training, I feel ready to tackle the digital landscape.
                </p>
              </div>
              <h2>Festus Bigirimana</h2>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                The soft skills training here was incredibly beneficial for my professional growth. 
                It helped me enhance my communication, teamwork, and leadership abilities.
                 The practical exercises and constructive feedback were invaluable.
                </p>
              </div>
              <h2>Grace Uwamahoro</h2>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="test-content">
              <div className="test-icon">
                <i className="fa-solid fa-quote-left"></i>
              </div>
              <div className='test-writings'>
                <p>
                I enrolled in the Kinyarwanda training to improve my language proficiency, 
                and I was impressed by the structured approach and engaging sessions. 
                The course also deepened my understanding of Rwandan culture.
                </p>
              </div>
              <h2>Sophie Anderson</h2>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>







      <section className='partnership'>
        <div className="partnership-text">
          <h1>Partnership</h1>
          <p>At CSR Training Center, we are proud to collaborate with esteemed organizations such as GIZ and RDB to enhance the quality of our training programs and support our participants'
            journey toward success.</p>

          <Link>
            <div className='button-icon know-more'>
              <h6>
                Know More
              </h6>
              <div className="header-icon">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                  <path d="M16.5 7.5L6 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M8 6.18791C8 6.18791 16.0479 5.50949 17.2692 6.73079C18.4906 7.95209 17.812 16 17.812 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>


          </Link>
        </div>

        <div className='part-img'>
          <img src="/images/home/partnership.png" alt="" />
        </div>
        <div className='yellow-vector'>
          <img src="/images/home/yellow-vector.svg" alt="" />
        </div>


      </section>
      <Howtoapply />
      <Footer />
    </div>

  );
}


export default HomePage

