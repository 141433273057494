// ReusableDatePicker.js
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar03Icon } from 'hugeicons-react';
import { format, addYears } from "date-fns";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input" onClick={onClick} ref={ref}>
        <input type="text" value={value} readOnly placeholder='YYYY-MMM-DD' />
        <Calendar03Icon className='icon' />
    </div>
));

const CustomDatePicker = ({ selectedDate, setSelectedDate }) => {
    const handleDateChange = (date) => {
        if (date) {
            setSelectedDate(format(date, "yyyy-MM-dd"));
        }
    };

    const maxDate = addYears(new Date(), -18);
    const minDate = addYears(new Date(), -31);

    return (
        <DatePicker
            selected={selectedDate ? new Date(selectedDate) : null}
            onChange={handleDateChange}
            customInput={<CustomInput />}
            placeholderText="Click to select date"
            dateFormat="yyyy-MM-dd"
            maxDate={maxDate}
            minDate={minDate}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={30}
            showMonthDropdown
            dropdownMode="select"
        />
    );
};

export default CustomDatePicker;