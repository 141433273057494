import React, { useState, useEffect } from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google'
import api, { API_URL } from '../../api';
import LoadingIcon from '../loadingIcon';

const GoogleRegistrationButton = () => {
    const [accessToken, setAccessToken] = useState("")
    const [loggingIn, setLoggingIn] = useState(false)
    const [registeringWithGoogle, setRegisteringWithGoogle] = useState(false)
    const googleRegister = useGoogleLogin({
        onSuccess: tokenResponse => {
            setAccessToken(tokenResponse.access_token);
        },
    });

    useEffect(() => {
        if (accessToken) {
            setRegisteringWithGoogle(true)
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    axios.post(`${API_URL}/register/social/google/`, { "access_token": accessToken })
                        .then(async (response) => {
                            console.log(response);
                            if (response.status === 201) {
                                localStorage.setItem("accessToken", response.data.access);
                                try {
                                    const profileData = await api.get(`${API_URL}/me/`)
                                    console.log(profileData.data.profile)
                                    localStorage.setItem("userProfile", JSON.stringify(profileData.data.profile))
                                } catch (error) {
                                    toast.error("Failed to load profile... please refresh")
                                    // setIsLoading(false)
                                }
                                window.location.reload();
                                setRegisteringWithGoogle(false);
                            }
                        })
                        .catch((error) => {
                            if (error.response.data) {
                                console.log(error.response.data.error);
                                toast.error(error.response.data.error)
                                setRegisteringWithGoogle(false);
                            } else {
                                toast.error("Network Error")
                                setRegisteringWithGoogle(false);
                            }
                        });

                })
                .catch((err) => console.log(err));
        };

    },
        [accessToken]
    );
    return (
        <div onClick={() => googleRegister()} className="social-button">
            {registeringWithGoogle
                ? <LoadingIcon size={18} />
                : <img src={"/images/google.svg"} alt="login-with-google" />

            }
            {
                registeringWithGoogle
                    ? <span>Processing</span>
                    : <span>With Google</span>
            }
        </div>
    )
}

export default GoogleRegistrationButton
