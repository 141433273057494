import React, { useEffect, useRef, useState } from 'react'
import api, { API_URL } from '../../api'
import { useParams } from 'react-router-dom'
import LoadingIcon from '../loadingIcon'
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react'
import toast from 'react-hot-toast'
import CustomSelectInput from './customSelectInput'
import CustomDatePicker from './customDateInput'

import { MAX_FILE_SIZE } from '../../api'
import mediaAPI from '../../apiMedia'

const ApplicationForm = ({ trainings }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const { cohortId } = useParams()
    const [currentStep, setCurrentStep] = useState(1)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [address, setAddress] = useState("")
    const [gender, setGender] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [training, setTraining] = useState("")
    const [trainingLevel, setTrainingLevel] = useState("")
    const [referee, setReferee] = useState("")
    const [cv, setCV] = useState(null)
    const cvInputRef = useRef(null)

    const handleCV = (event) => {
        const file = event.target.files[0]

        if (file && file.size > MAX_FILE_SIZE) {
            toast.error("File must not exceed 12MB")
            setCV(null)
            if (cvInputRef.current) {
                cvInputRef.current.value = '';
            }
            return;
        }
        setCV(event.target.files[0]);
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1)
    }
    const handlePreviousStep = (e) => {
        e.preventDefault()
        setCurrentStep(currentStep - 1)
    }
    const handleAPplication = async () => {
        const applicationData = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone_number": phone,
            "password": password,
            "address": address,
            "gender": gender,
            "date_of_birth": dateOfBirth,
            "training_level": trainingLevel,
            "training": training,
            "referee": referee,
            // "cv": cv,
        }
        setIsLoading(true)
        // form validation
        console.log(applicationData)
        if (!firstName || !lastName || !email || !phone || !password || !confirmPassword || !address || !gender || !dateOfBirth || !trainingLevel || !referee) {
            console.log(cv)
            alert("Please fill all the required fields")
            return
        }
        if (password !== confirmPassword) {
            alert("Passwords do not match")
            return
        }
        // form submission

        try {

            const response = await mediaAPI.post(`${API_URL}/trainings/cohorts/${cohortId}/apply/`, applicationData)
            if (response.status === 201) {
                setIsLoading(false)
                setSuccessMessage("Your application has been successfully submitted. Our team will reach out to you soon. You should send your cvd to info@csrlimited.com")
                setFirstName("")
                setLastName("")
                setEmail("")
                setPhone("")
                setPassword("")
                setConfirmPassword("")
                setAddress("")
                setGender("")
                setDateOfBirth("")
                setTrainingLevel("")
                setReferee("")
                setCV("")

                setTimeout(() => {
                    window.location.href = "/"
                    setSuccessMessage("")
                }, 2000);
            }
        } catch (error) {
            setIsLoading(false)
            if (error.response) {
                toast.error(error.response.data.message)
            }
            console.error(error)
        }
    }

    useEffect(() => {

    }, [])
    return (
        <div className='application-form'>
            <form action="">
                {
                    currentStep === 1
                        ? <>
                            <div className="half">
                                <div className="field">
                                    <label htmlFor="firstName">First name?</label>
                                    <input onChange={(e) => setFirstName(e.target.value)} type="text" name="firstName" id="lastName" placeholder='First name' />
                                </div>
                                <div className="field">
                                    <label htmlFor="lastName">Last name?</label>
                                    <input onChange={(e) => setLastName(e.target.value)} type="text" name="lastName" id="lastName" placeholder='Last name' />
                                </div>
                            </div>
                            <div className="half">
                                <div className="field">
                                    <label htmlFor="email">Email?</label>
                                    <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" placeholder='Email' />
                                </div>
                                <div className="field">
                                    <label htmlFor="phone">Phone?</label>
                                    <input onChange={(e) => setPhone(e.target.value)} type="tel" name="phone" id="phone" placeholder='Phone' />
                                </div>
                            </div>

                            <div className="half">
                                <div className="field">
                                    <label htmlFor="dateOfBirth">When were you born?</label>
                                    {/* <input onChange={(e) => setDateOfBirth(e.target.value)} type="date" name="dateOfBirth" id="dateOfBirth" /> */}
                                    <CustomDatePicker selectedDate={dateOfBirth} setSelectedDate={setDateOfBirth} />
                                </div>

                                <div className="field">
                                    <label htmlFor="gender">Gender</label>
                                    <CustomSelectInput
                                        options={[
                                            "Male", "Female", "Other"
                                        ]}
                                        selected={gender}
                                        setSelected={setGender}
                                        placeholder={"gender"}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="address">Address?</label>
                                <input onChange={(e) => setAddress(e.target.value)} type="text" name="address" id="address" placeholder='Example: Kigali, Kicukiro, Gatenga' />
                            </div>

                        </>
                        : currentStep === 2
                            ? <>

                                <div className="half">
                                    <div className="field">
                                        <label htmlFor="trainingToJoin">Which training are you interested in?</label>
                                        <CustomSelectInput
                                            options={
                                                trainings.map((training) => training.name)
                                            }
                                            selected={training}
                                            setSelected={setTraining}
                                        />

                                    </div>
                                    <div className="field">
                                        <label htmlFor="trainingLevel">What is your level of understanding in that industry?</label>
                                        <CustomSelectInput
                                            placeholder={"training level"}
                                            options={[
                                                "Beginner", "Intermediate", "Advanced"
                                            ]}
                                            selected={trainingLevel}
                                            setSelected={setTrainingLevel}
                                        />

                                    </div>
                                </div>

                                <div className="field">
                                    <label htmlFor="referee">How did you find us?</label>
                                    <CustomSelectInput options={
                                        [
                                            "CSR Platform", "Partners", "Friend"
                                        ]

                                    }
                                        placeholder={"referee"}
                                        selected={referee}
                                        setSelected={setReferee}
                                    />
                                </div>

                                {/* Add reference type: CSR Platform, Partners, Friend */}

                                {/* <div className="field">
                                    <label htmlFor="cv">Upload you CV here</label>
                                    <input onChange={handleCV} ref={cvInputRef} type="file" name="cv" id="cv" />
                                </div> */}
                                <p>We'll create an account for you, and you can use it later. Fill the following fields too!</p>
                                <div className="half">
                                    <div className="field">
                                        <label htmlFor="password">Password</label>
                                        <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder='Password' />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" name="confirmPassword" id="confirmPassword" placeholder='Confirm Password' />
                                    </div>
                                </div>
                            </> :
                            ''
                }
                {
                    successMessage && (
                        <div className="success-message">
                            {successMessage}
                        </div>
                    )
                    // error && (
                    //     <div className="error-message">
                    //         {error}
                    //     </div>
                    // )
                }
                <div className="buttons">
                    {
                        currentStep < 2 && currentStep > 0 ? (
                            <button onClick={handleNextStep} type="button" className="primary-button">Next</button>
                        ) :
                            <>
                                <button onClick={handlePreviousStep} type="button">
                                    <ArrowLeft01Icon size={22} />
                                    <span>Back</span>
                                </button>
                                <button onClick={handleAPplication} type="button" className='primary-button'>
                                    {
                                        isLoading
                                            ? <>
                                                <LoadingIcon size={18} />
                                                <span>Submitting</span>
                                            </>
                                            : <>
                                                <span>Submit application</span>
                                                <ArrowRight01Icon size={22} />
                                            </>
                                    }

                                </button>
                            </>
                    }
                </div>
            </form>
        </div>
    )
}

export default ApplicationForm
