import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "./authContext";
import AuthPopup from "../forms/authPopup";
function ProtectedRoute({ children }) {
    const { isAuth, } = useAuthentication()
    if (isAuth == null) {
        return null
    }

    return isAuth ? children : <AuthPopup withBackground={true} refreshPage={true} />
}

export default ProtectedRoute
