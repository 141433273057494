import React, { useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Loading02Icon } from 'hugeicons-react'
import api, { API_URL } from '../../api'

import '../../css/auth/forms.css'
import { Link } from 'react-router-dom'
import LoadingIcon from '../loadingIcon'
import GoogleLoginButton from './googleLoginButton'
const LoginForm = ({ setIsNewUser }) => {
    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const handleLogin = async () => {
        if (userEmail === '' || userPassword === '') {
            toast.error('Please fill in all fields')
            return
        }

        if (userEmail === '') {
            toast.error('Please enter a valid email address')
            return
        }
        if (userPassword === '') {
            toast.error('Please enter a password')
            return
        }
        const userCredentials = {
            "email": userEmail,
            "password": userPassword,
        }
        setIsLoading(true)
        try {
            const response = await axios.post(`${API_URL}/token/`, userCredentials)
            if (response.status === 200) {
                localStorage.setItem('accessToken', response.data.access)
                const profileData = await api.get(`${API_URL}/me/`)
                console.log(profileData.data.profile)
                localStorage.setItem("userProfile", JSON.stringify(profileData.data.profile))
                window.location.reload()
                toast.success('Login successful')
                window.location.reload()
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data) {
                toast.error(error.response.data.error)
                setIsLoading(false)
            } else {
                toast.error('Failed to login. Please try again.')
                setIsLoading(false)
            }
        }
    }
    return (
        <div className='login-form auth-form'>
            <div className="form-container">
                <div className="form-title">Sign in to Your Account</div>
                <div className="social-buttons">
                    <GoogleLoginButton />
                    {/* <div className="social-button">
                        <img src={"/images/microsoft.svg"} alt="login-with-microsoft" />

                        <span>Sign in with Microsoft </span>
                    </div> */}
                </div>

                <p className="or">Or</p>
                <form action="" id="LoginForm">
                    <input onChange={(e) => setUserEmail(e.target.value)} value={userEmail} type="text" name="userEmail" id="userEmail" placeholder='Enter your email' />
                    <input onChange={(e) => setUserPassword(e.target.value)} value={userPassword} type="password" name="userPassword" id="userPassword" placeholder='Enter your password' />

                    <div className="form-links">
                        <Link className='form-link'>For for password?</Link>
                        <p onClick={() => setIsNewUser(true)} className='form-link'>Need an account?</p>
                    </div>
                    <button onClick={handleLogin} type="button" className="primary-button">
                        {
                            isLoading
                                ? <LoadingIcon size={18} />

                                : <span>Login</span>
                        }

                    </button>
                </form>
            </div>
        </div>
    )
}

export default LoginForm
