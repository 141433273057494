import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import api, { API_URL } from '../../api'
import toast from 'react-hot-toast'
import LoadingIcon from '../loadingIcon'
import GoogleRegistrationButton from './googleRegisterButton'
const RegistrationForm = ({ setIsNewUser }) => {
    const [userFirstName, setUserFirstName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleRegistration = async () => {
        setIsLoading(true)
        const userDetails = {
            "first_name": userFirstName,
            "last_name": userLastName,
            "email": userEmail,
            "password": userPassword,
        }
        try {
            const response = await axios.post(`${API_URL}/register/`, userDetails)
            if (response.status === 201) {
                toast.success('Registration successful')
                window.location.reload()
                setIsNewUser(false)
                setIsLoading(false)
            }
        } catch (error) {
            if (error.response.data) {
                toast.error(error.response.data.error)
                setIsLoading(false)
            } else {
                toast.error('Failed to register user. Please try again.')
                setIsLoading(false)
            }
        }
    }
    return (
        <div className='registration-form, auth-form'>
            <div className="form-container">
                <div className="form-title">Create a New Account</div>
                <div className="social-buttons">
                    <GoogleRegistrationButton />
                    {/* <div className="social-button">
                        <img src={"/images/microsoft.svg"} alt="login-with-microsoft" />

                        <span>With Microsoft </span>
                    </div> */}
                </div>

                <p className="or">Or</p>
                <form action="" id="registrationForm">
                    <input onChange={(e) => setUserFirstName(e.target.value)} value={userFirstName} type="text" name="" id="userFirstName" placeholder='First name' />
                    <input onChange={(e) => setUserLastName(e.target.value)} value={userLastName} type="text" name="" id="userLastName" placeholder='Last name' />
                    <input onChange={(e) => setUserEmail(e.target.value)} value={userEmail} type="text" name="" id="userEmail" placeholder='Email' />
                    <input onChange={(e) => setUserPassword(e.target.value)} value={userPassword} type="password" name="" id="userPassword" placeholder='Password' />
                    <div className="form-links">
                        <Link className='form-link'>For for password?</Link>
                        <p onClick={() => setIsNewUser(false)} className='form-link'>Already have an account?</p>
                    </div>
                    <button onClick={handleRegistration} type="button" className='primary-button'>
                        {
                            isLoading
                                ? <LoadingIcon size={18} />
                                : <span>Register</span>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}

export default RegistrationForm
