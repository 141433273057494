import React, { useState } from 'react'
import LoginForm from './loginForm'
import RegistrationForm from './registrationForm'
import { Link } from 'react-router-dom'

const AuthPopup = ({ withBackground, refreshPage }) => {
    const [isNewUser, setIsNewUser] = useState(false)
    return (
        <div className='authPopup'>
            <img className='pattern-right' src={"/images/home/pattern left.svg"} alt="" />
            <img className='pattern-left' src={"/images/home/pattern right.svg"} alt="" />
            <div className="auth-popup-content">
                <div className="form-content">
                    <div className="form-text">
                        <div className="welcome-text">
                            <Link to={"/"}>
                                <img src={"/images/CSR Logo white.svg"} alt="" />
                            </Link>
                            <h2 className='welcome-title'>WELCOME TO CSR Training Center</h2>
                            <h2 className='title'>Our training center serves as a <span>dynamic hub</span> for learning and development.</h2>
                        </div>
                    </div>

                </div>
                {
                    isNewUser
                        ? <RegistrationForm setIsNewUser={setIsNewUser} />
                        : <LoginForm setIsNewUser={setIsNewUser} />
                }
            </div>
        </div>
    )
}

export default AuthPopup
