import React, { useState } from "react";
import { Link } from "react-router-dom";
import api, { API_URL } from "../../api";
import toast from "react-hot-toast";
import LoadingIcon from "../loadingIcon";
import { MultiplicationSignIcon } from 'hugeicons-react';
import { useAuthentication } from "../contexts/authContext";

const PaymentForm = ({ serviceName, amount, onClose  }) => {
    const { user } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [registeredPhoneNumber, setRequestedPhoneNumber] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [isMobileMoneyChecked, setIsMobileMoneyChecked] = useState(false);
    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [buttonText, setButtonText] = useState("Click to Pay");
    


    const userEmail = localStorage.getItem('user_email');
        const userFullName = localStorage.getItem('user_full_name');
    const isButtonEnabled = isMobileMoneyChecked && isTermsAgreed && !isLoading;
    const buttonClassName = isButtonEnabled ? 'primary-button' : 'payment'; 

    const handlePayment = async () => {
        
        const paymentData = {
            "email": userEmail,
            "amount": amount,
            "phone_number": registeredPhoneNumber,
            "transaction_mode": "MTN MoMo",
            "name": userFullName,
            "service": serviceName
        }

        try {
            setIsLoading(true)
            setButtonText('Initiating payment')
            console.log(paymentData)
            const response = await api.post(`${API_URL}/payment/`, paymentData)
            if (response.status === 200) {
                toast.success('Payment initiated')
                console.log(response.data)
                setButtonText('Processing')
                const intervalId = setInterval(async () => {
                    try {
                        const confirmationResponse = await api.get(`${API_URL}/confirmation_api/${response.data.ref}/`);

                        if (confirmationResponse.status === 200) {
                            const status = confirmationResponse.data.status;

                            if (status === 'successful') {
                                clearInterval(intervalId);
                                setIsLoading(false)
                                window.location.reload()
                                setButtonText('Payment Successful')
                                // take a user to the receipt page
                                return;
                            } else if (status === 'failed') {
                                window.location.reload()
                                clearInterval(intervalId);
                                setIsLoading(false)
                                setButtonText('Failed')
                                return;
                            } else if (status === 'pending') {
                                setButtonText('Pending')

                            }
                            console.log(confirmationResponse.data);
                        }
                    } catch (error) {
                        console.error('Error fetching confirmation status:', error);
                        clearInterval(intervalId);
                        setIsLoading(false)
                        setButtonText("Try again")
                    }
                }, 1000);

            }
        } catch (error) {
            console.log(error)
            if (error.response.data.error) {
                toast.error(error.response.data.error)
                setButtonText("Try again")
                setIsLoading(false)
            }
            else {
                toast.error('Error initiating payment. Please try again later.')
                setButtonText("Try again")
                setIsLoading(false)
            }
        }

    

    };

    return (
        <div className="payment-content">
            <div className="payment-icon" onClick={onClose} >
               
                <MultiplicationSignIcon
                    size={18}
                    color={"#000000"}
                    variant={"stroke"}
                />
            </div>
            <div className="service-info">
                <h3 className="service-name">{serviceName}</h3>
            </div>

            <div className="payment-note">
                Before you pay, you must have {amount}Rwf on your AirTel or MTN mobile money account.
            </div>

            <form id="paymentForm">
                <p>Pay using AirTel of MTM Mobile Money</p>
                <div className="check-box">
                    <input
                        type="radio"
                        name="mobileMoney"
                        id="mobileMoney"
                        checked={isMobileMoneyChecked}
                        onChange={() => setIsMobileMoneyChecked(!isMobileMoneyChecked)}
                    />
                    <label htmlFor="mobileMoney">Mobile Money</label>
                </div>

                <input
                    onChange={(e) => setRequestedPhoneNumber(e.target.value)}
                    type="text"
                    name="mobileMoneyRegisteredName"
                    id="mobileMoneyRegisteredName"
                    placeholder="Mobile money registered number"
                />
                <div className="check-box">
                    <input
                        type="checkbox"
                        name="termsAgreement"
                        id="termsAgreement"
                        checked={isTermsAgreed}
                        onChange={() => setIsTermsAgreed(!isTermsAgreed)}
                    />
                    <label htmlFor="termsAgreement">
                        I agree to the terms and services, <Link className="form-link">Read terms and conditions</Link>
                    </label>
                </div>

                <button
                    onClick={handlePayment}
                    type="button"
                    className={buttonClassName} 
                    disabled={!isButtonEnabled}
                >
                    {isLoading ? <LoadingIcon size={18} /> : ''}
                    {buttonText}
                </button>
            </form>
        </div>
    );
};

export default PaymentForm;
