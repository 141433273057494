import React from 'react'
import { ArrowRight01Icon, ArrowDown02Icon } from 'hugeicons-react'
import '../css/heroSection/hero_section.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
const HeroSection = () => {

    return (
        <section className="hero-section">
            <img src={"/images/home/pattern left.svg"} alt="" className="pattern-left" />
            <img src={"/images/home/pattern right.svg"} alt="" className="pattern-right" />
            <div className="container">
                <div className="hero-images left-images">
                    <img src={"/images/home/big.png"} alt="" className="big" />
                    <img src={"/images/home/small.png"} alt="" className="small" />
                </div>
                <div className="hero-content">
                    <h3>Welcome to CSR Training Center</h3>
                    <h1 className="hero-title">Our training center serves as a <span>dynamic hub</span> for learning and development.</h1>
                    <div className="buttons">


                        <Link to={"/trainings/cohort/2/apply/"} className="button secondary-button" >
                            <span className="button-text">Contact us</span>
                            <div className="icon">
                                <ArrowRight01Icon
                                    size={24}
                                    variant={"stroke"}
                                />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="hero-images right-images">
                    <img src={"/images/home/big.png"} alt="" className="big" />
                    <img src={"/images/home/kids.png"} alt="" className="small right" />
                </div>
            </div>
            <a href="#about">
                <div className="move-down-icon">
                    <svg className='circle' width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="42.5" cy="42.5" r="41.5" stroke="#145C74" stroke-width="2" stroke-dasharray="16 16" />
                    </svg>


                    <div className='icon'>
                        <ArrowDown02Icon
                            size={24}
                            variant={"stroke"}
                        />
                    </div>
                </div>
            </a>
        </section>
    )
}

export default HeroSection
