import React from 'react'
import Header from "../components/header"
import "../css/partnership/partnership.css"
import Footer from "../components/footer"

const PartnershipPage = () => {
  return (
    <div>
      <Header />
      <section className='partnership-section'>
        <div className='part-container'>

        <div className='parnership-tex'>
            <div className='text'>
              <div className='text-header textheader'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
               <circle cx="5" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" />
              <circle cx="8" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="17" cy="7" r="5" stroke="currentColor" strokeWidth="1.5" />
</svg>        
              <h2>  PARNERSHIP</h2>
            
              </div>
                
             <h3 className='text-paragraph'>
                We are proud to collaborate with esteemed 
                organizations such as GIZ  and RDB to enhance the <br />
                quality of our training programs and 
                 support our participants' journey toward success. <br /> </h3>
             </div>
        </div>
        



          <section className='training-images'>
            <div className='training-container'>
            <div className='images-container'>
               <div className='image1'>
                <img src="/images/partnership/training-image.png" alt="" />
               </div>
          </div>

               <div className='text-container'>
                <p>GIZ (German Corporation for International Cooperation) 
                    and RDB (Rwanda Development Board) are key partners in our 
                    mission to empower fresh graduates and equip them with the skills 
                    necessary to excel in the job market. Through strategic collaborations
                     with these organizations, we are able to offer comprehensive training programs that 
                    address the evolving needs of the workforce.</p>
               </div>
            </div>
          </section>



          <section className='bluebackground-section'>
          {/* <img src="/images/partnership/Vector-img.svg" alt="" /> */}
            <div className='training-group'>

            <div className='group1-img'>
                <div className='group-a'>
                   <img src="/images/partnership/Group1.png" alt="" />
                </div>

                <div className='group-b'>
                   <img src="/images/partnership/giz.png" alt="" />
                </div>

                <div className='text-white'>
                    <p>GIZ, a global development agency, brings extensive 
                        expertise in skills development and capacity-building 
                        initiatives. Their support ensures that our training programs 
                        are aligned with industry standards and best practices, providing participants 
                        with relevant and up-to-date skills that are
                         in demand in today's competitive job market.</p>
                </div>
            </div>

              
              <div className='group2-img'>
              <div className='group-a'>
                   <img src="/images/partnership/Group2.png" alt="" />
                </div>

                <div className='group-b'>
                   <img src="/images/partnership/rdb.png" alt="" />
                </div>

                  <div className='text-white'>
                    <p> Similarly, RDB plays a crucial role in facilitating the
                         transition of students into the workforce by providing logistical support by providing
                         them with monthly transport allowance</p>
                     </div>

              </div>
            </div>
          </section>





          <section className='benefits'>
            <div className='benefits-container'>
               
                <div className='benefitsofPartnerships'>
                  <div className='text-header'>
                  <h2>BENEFITS</h2>
                  </div>
                     
                    <h1 className='color-blue'>Benefits of Partnerships for <br /> Participants:</h1>
                </div>
                <div className='benefit-rows'>
                    <div className='benefit1'>
                        <div className='singler-benefit-a'>
                            <h3>Access toIndustry-Relevant Training</h3>
                            <p>Through our partnerships, participants gain
                                access to training programs that are developed in collaboration
                                with industry experts 
                                 and tailored to meet the demands of the job market.</p>
                        </div>
                         
                      
                    </div>

                    <div className='benefit1'>
                    <div className='singler-benefit-a'>
                            <h3>Enhanced Learning Experience</h3>
                            <p>With the support of GIZ and RDB, we are able to offer 
                                a holistic learning experience that goes beyond classroom instruction.
                                 Participants benefit from practical training, mentorship opportunities, and access 
                                to resources that enhance their learning journey.</p>

                         </div>
                    </div>


                    <div className='benefit2'>
                        <div className='singler-benefit-b'>
                            <h3>Comprehensive Support Services</h3>
                            <p>Our partnerships enable us to provide participants with 
                                additional support services, such as transportation 
                                allowance and free breakfast and lunch.</p>
                        </div>

                      
                    </div>

                     <div className='benefit2'>
                       <div className='singler-benefit-b'>
                            <h3>Professional Certification</h3>
                            <p>Professional Certification: Every participant who completes our
                                 training programs receives a professional certificate, validating their skills and knowledge in 
                                 their respective fields. This certificate serves as a testament to their achievements 
                                and enhances their credibility in the job market.</p>
                        </div>
                       </div>

                      
                        </div>

                        <div className=' benefit3'>
                          <div className='benefit-5'>
                            <h3>Increased Employability</h3>
                            <p>By completing our training programs, participants acquire valuable
                                 skills and credentials that enhance their employability and position them for success in their chosen fields.
                                  Upon completion, CSR guarantees employment for 85% of trainees, while the remaining participants are deployed under the CSR Job Match program, connecting them with suitable 
                                employment opportunities in partner organizations</p>
                        </div>
                        </div>

            </div>

          </section>

        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default PartnershipPage

