import React, { useEffect } from "react";
import Header from "../components/header";
import { Services } from "../components/services";
import { useParams } from "react-router-dom";
import Footer from "../components/footer";

const ServicePage = () => {
  const { serviceId } = useParams();
  console.log('Service ID:', serviceId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Services serviceId={serviceId} />
      <Footer />
    </>


  )

}

export default ServicePage;