import React from 'react'
import '../css/programs/programs.css'
import Howtoapply from '../components/howtoapply'
import Header from '../components/header'
import Footer from '../components/footer'

const programsPage = () => {
  return (
    <>
    <Header/>
    <div className='programs-content'>
      <div className="left-content">
        <div className="programs-header">
        <div className="text-header">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
    <circle cx="5" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="8" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="17" cy="7" r="5" stroke="currentColor" strokeWidth="1.5" />
        </svg>
          <h2>Programs</h2>
        </div>
        <h1>Training Programs</h1>
        <p>Our training programs are categorized into different areas, including technical skills, soft skills, and language training.
           Below is a list of available programs under each category:</p>
        </div>
       

       <div className='programs-img'>
        <img src="/images/programs/programs.png" alt="" />
       </div>
       

       <p>Each program is designed to meet the specific needs of <br /> participants, with flexible schedules and affordable fees. Duration and fees vary depending
         on the program selected.</p>

      </div>

      <div className="right-content">
      
        <div className="skills">
          <div className="skills-header">
            <div className="skills-icon">
         <img src="/images/programs/technical.svg" alt="" />
            </div>
            <h3>Technical <br />Skills:</h3>
          </div>

          <div className="skills-content">
            <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Graphic Design</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Tech Support</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Software Development</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Accounting</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Digital Marketing</p>
          </div>
          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Programming</p>
          </div>
          </div>
      

        <div className="skills">
          <div className="skills-header need-space">
            <div className="skills-icon">
             <img src="/images/programs/customer.svg" alt="" />
            </div>
            <h3>Customer <br /> Care</h3>
          </div>

          <div className="skills-content">
            <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Understanding Customer Needs</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Effective Communication</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Handling inquiries and <br />
               providing accurate information</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Dealing with difficult customers</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Relationship-building techniques</p>
          </div>
          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Personalization and customization</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Time Management</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Cultural Sensitivity</p>
          </div>
          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Utilizing communication channels 
               (phone, email, chat, social media)</p>
          </div>
          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Seeking feedback from customers</p>
          </div>
          </div>
        </div>

        </div>
        
        <div className="skills">
          <div className="skills-header">
            <div className="skills-icon">
            <img src="/images/programs/softskills.svg" alt="" />
            </div>
            <h3>Soft <br />Skills</h3>
          </div>

          <div className="skills-content">
            <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Interpersonal Skills</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Leadership Skills</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Adaptability</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Creativity and Innovation</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Conflict Resolution</p>
          </div>
          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Critical Thinking</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Cultural Competence</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Self-Management</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Communication Skills</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Time Management</p>
          </div>
          </div>
       

        <div className="skills">
          <div className="skills-header need-space">
            <div className="skills-icon">
         <img src="/images/programs/language.svg" alt="" />
            </div>
            <h3> Language <br /> Training</h3>
          </div>

          <div className="skills-content">
            <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p> English Language</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>French Language</p>
          </div>

          <div className="skills-text">
            <div className="single-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#145C74"} fill={"none"}>
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
             </svg>
            </div>
            <p>Kinyarwanda Language</p>
          </div>

         
          </div>
        </div>
        </div>
      </div>
    </div>
    <Howtoapply/>
    <Footer/>
    </>
  )
}

export default programsPage
