import React, { useEffect, useState } from "react";
import { useAuthentication } from "./contexts/authContext";
import toast from "react-hot-toast";
import "../css/servicess/services.css";
import { useLocation } from "react-router-dom";
import ServiceForm from "./serviceForm";
import ServiceCard from "./serviceCard";
import LoginForm from "./forms/loginForm";
import AuthPopup from "./forms/authPopup";
import { ArrowRight01Icon } from "hugeicons-react";
import PaymentForm from "./forms/paymentForms";
import LoadingIcon from "./loadingIcon";
import api, { API_URL } from "../api";
import axios from "axios";
const DetailsCard = ({ detail }) => {

  return (
    <>
      <div className="details-div">
        {detail}
      </div>
    </>
  )
}

export const Services = ({ serviceName, serviceId }) => {
  const [isDev, setIsDev] = useState(true);
  const { isAuth } = useAuthentication();
  const [showForm, setShowForm] = useState(false);
  const [loadingService, setLoadingService] = useState(true);
  const [serviceDetails, setServiceDetails] = useState({});

  useEffect(() => {
    if (serviceId) {
      const getService = async () => {
        try {
          const response = await axios.get(`${API_URL}/trainings/${serviceId}/`)
          if (response.status === 200) {
            setLoadingService(false);
            setServiceDetails(response.data);
            console.log(response.data)
          }
        }
        catch (error) {
          if (error.response.data.error) {
            toast.error(error.response.data.error)
          } else {
            toast.error('Failed to fetch service details')
          }
        }
      }

      getService();

    }
  }, [serviceId]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  console.log(isAuth);
  const { state } = useLocation();
  const service = state?.service;

  const { training, outlines, benefits } = serviceDetails;
  return loadingService ? (
    <div className="loading-content-service">
      <LoadingIcon />
      <h3>Getting data</h3>
    </div>
  ) : (
    <>
      <div className="services-page-container">
        <div className="services-container">
          {
            isDev ?
              <div className="services-first-row">

                <div className="service-image">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/services/placeholder.png`}
                    alt=""
                  />
                </div>
              </div> : ''
          }


          <div className="services-second-row">
            <div className="services-details">
              <div className="details-text">
                <div className="service-title">
                    
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="46" height="46" rx="12" fill="#EAF9FF" />
                    <path
                      d="M17.8896 20C18.8696 20.49 19.7096 21.23 20.3196 22.15C20.6696 22.67 20.6696 23.34 20.3196 23.86C19.7096 24.77 18.8696 25.51 17.8896 26"
                      stroke="#145C74"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24 26H28"
                      stroke="#145C74"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 33H26C31 33 33 31 33 26V20C33 15 31 13 26 13H20C15 13 13 15 13 20V26C13 31 15 33 20 33Z"
                      stroke="#145C74"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>
                    {training.name}
                  </h3>

                </div>

                {training.short_description}
              </div>
              <div className="cards">
                {outlines && outlines.map((outline) => (
                  <ServiceCard
                    key={outline.id}
                    serviceTitle={outline.title}
                    icon={`${process.env.PUBLIC_URL}/images/services/custom.svg`}
                    details={outline.description}
                  />
                ))}
              </div>
              {
                showForm && isAuth

                  ? <ServiceForm PopupContent={<PaymentForm serviceName={training.name} amount={training.price} />} />
                  : showForm ? <AuthPopup />
                    : ''
              }

              <button onClick={toggleForm} type="button" className="primary-button">
                <span>Get started now</span>
                <span className="icon">
                  <ArrowRight01Icon
                    size={24}
                    color={"#000000"}
                    variant={"stroke"}
                  />
                </span>
              </button>
            </div>
          </div>
        </div>

        {
          isDev
            ? <div className="second-section">
              <div className="title">
                {training.call_to_action}
              </div>
              <div className="details">
                {training.derailed_description}
              </div>

              <div className="price-and-duration">
                <div className="price-div">
                    <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/services/cost.svg`} alt="price" />
                    </div>
                    <div className="cost">
                        <p>Training Cost</p>
                        <p>{training.price} Rwf</p>
                    </div>
                </div>
                <div className="duration-div">
                <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/services/duration.svg`} alt="duration" />
                    </div>
                    <div className="cost">
                        <p>Training Duration</p>
                        <p>{training.duration_in_months} months</p>
                    </div>
                </div>
              </div>
              <div className="detail-title">
                Key Benefits
              </div>
              <div className="detail-card">
                {benefits.map((benefit) => (
                  <DetailsCard
                    key={benefit.id}
                    detail={benefit.title} />
                ))}

              </div>
            </div>
            : ''
        }

      </div>

    </>
  )
};
