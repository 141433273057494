import React, { useEffect, useState } from 'react'
import ApplicationForm from '../forms/applicationForm'
import { Layers01Icon, SubtitleIcon } from 'hugeicons-react'
import Header from '../header'
import Footer from '../footer'
import '../../css/applicationPage/applicationPage.css'
import api, { API_URL } from '../../api'
import { useParams } from 'react-router-dom'
import SlicedText from '../slicedText'
const ApplicationPage = () => {
    localStorage.clear()
    const { cohortId } = useParams()
    const [trainings, setTrainings] = useState([])

    const [cohort, setCohort] = useState({})

    useEffect(() => {
        const getCohortInfo = async () => {
            try {
                const response = await api.get(`${API_URL}/trainings/cohorts/${cohortId}/`)
                if (response.status === 200) {
                    setCohort(response.data)
                    console.log(response.data)
                }
            } catch (error) {
                console.error(error)
            }
        }
        getCohortInfo()

        const fetchingTrainings = async () => {
            try {
                const response = await api.get(`${API_URL}/trainings/`)
                if (response.status === 200) {
                    setTrainings(response.data)
                    console.log(response.data)
                }
            } catch (error) {

            }
        }
        fetchingTrainings()
    }, [])

    return (
        <div className='application-page'>
            <Header />
            <div className="container">
                <div className="content">
                    <div className="title">
                        <div className="icon">
                            <SubtitleIcon size={22} />
                        </div>
                        <h3>CSR Trainings: {cohort.name}</h3>

                    </div>
                    <p>{cohort.description}</p>
                    <div className="trainings">
                        {cohort.trainings && cohort.trainings.map((training, index) => (

                            <div key={index} className="training">
                                <div className="icon">
                                    <Layers01Icon />
                                </div>
                                <div className="training-content">
                                    <h4 className='training-title'>{training.name}</h4>
                                    <p><SlicedText text={training.short_description} limit={50} /></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form">
                    <ApplicationForm trainings={trainings} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ApplicationPage
