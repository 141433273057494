import axios from "axios";
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // 12 MB in bytes
export const API_URL = process.env.REACT_APP_API_URL;
export const AuthClientId = process.env.REACT_APP_MS_CLIENT_ID
export const Auth_CompStaffing_Tenant = process.env.REACT_APP_MS_COMPSTAFIFNG_TENANT
export const Auth_Ms_redirectUri = process.env.REACT_APP_MS_REDIRECT_URL
export const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default api
