import React, { useEffect, useState } from 'react'
import api, { API_URL } from '../../api'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Header from '../header'
import Footer from '../footer'

const CohortsPageContent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [cohorts, setCohorts] = useState([])
    useEffect(() => {
        const fetchCohorts = async () => {
            try {
                const response = await api.get(`${API_URL}/trainings/cohorts/`)
                if (response.status === 200) {
                    setIsLoading(false)
                    setCohorts(response.data)
                    console.log(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('Failed to fetch cohorts')
                }
                setIsLoading(false)
                return
            }
        }
        fetchCohorts()
    }, [])
    return (
        <div className="cohorts-page">
            {
                cohorts && cohorts.length > 0
                    ? <div className="cohorts">
                        {
                            cohorts.map((cohort, index) => (
                                <Link to={`/dashboard/cohorts/${cohort.id}/applications/`} key={index} className="cohort">
                                    <h2>{cohort.name}</h2>
                                    <p>Start Date: {cohort.start_date}</p>
                                    <p>End Date: {cohort.end_date}</p>

                                </Link>
                            ))
                        }
                    </div>
                    : ''
            }
        </div>
    )
}
const CohortsPage = () => {
    return (
        <div className='cohorts-page'>
            <Header />
            <div className="container">
                <CohortsPageContent />
            </div>
            <Footer />
        </div>
    )
}

export default CohortsPage
