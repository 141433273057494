import React from "react";

const ServiceCard = ({ serviceTitle, details, icon }) => {
    return (
        <>
            <div className="card-container">
                <div className="card-title">
                    <img src={icon} alt={serviceTitle} />
                    <h4 className="title">{serviceTitle}</h4>
                </div>
                <div className="details">
                    {details}
                </div>
            </div>
        </>
    )


}

export default ServiceCard;