import React from 'react'
import { Loading02Icon } from 'hugeicons-react'

const LoadingIcon = ({ size }) => {
    return (
        <Loading02Icon className='loading-icon'
            size={size}
            variant={"stroke"}
        />
    )
}

export default LoadingIcon
