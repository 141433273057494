import React from 'react';

const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract parts of the date
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    // Format hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
    const formattedTime = `${hours}:${minutes}${ampm}`;

    return `${day} ${month} ${year} ${formattedTime}`;
};

const HumanReadableDate = ({ date }) => {
    return <div>{formatDate(date)}</div>;
};

export default HumanReadableDate;