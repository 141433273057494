import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";



const ServiceForm = ({ PopupContent }) => {
  return (
    <div className="service-form-container bernice-form">
      {PopupContent}
    </div>
  )

}

export default ServiceForm;