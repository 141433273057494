import { FileExportIcon } from 'hugeicons-react';
import React from 'react';
import * as XLSX from 'xlsx';

const ExportExcel = ({ data }) => {
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            data.map(app => ({
                'Full Name': `${app.user.first_name} ${app.user.last_name}`,
                'Email': app.user.email || '-',
                'Phone Number': app.phone_number || '-',
                'Gender': app.gender || '-',
                'Date Created': app.date_created ? new Date(app.date_created).toLocaleDateString() : '-',
                'Status': app.status || '-'
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications');
        XLSX.writeFile(workbook, 'ApplicationsExport.xlsx');
    };

    return (
        <button className='primary-button' onClick={exportToExcel}> Export <FileExportIcon size={20} /></button>
    );
};

export default ExportExcel;