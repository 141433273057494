import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './App.css';
import HomePage from "./pages/homePage"
import PartnershipPage from "./pages/partnershipPage"
import ProgramsPage from "./pages/programsPage"
import HeroSection from './components/heroSection';
import ServicePage from './pages/servicePage';
import ProtectedRoute from './components/contexts/protectedRoute';
import { GoogleClientId } from './api';
import ProfilePage from './pages/dashboard/profilePage';
import ApplicationPage from './components/pages/applicationPage';
import CohortApplicationsPage from './components/pages/dashbaord/cohorts/cohortApplications';
import CohortsPage from './components/pages/cohorts';

function App() {
  return (
    <GoogleOAuthProvider clientId={GoogleClientId}>
      <>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path="/partnership/" element={<ProtectedRoute><PartnershipPage /></ProtectedRoute>} />
            <Route path="/programs/" element={<ProgramsPage />} />
            <Route path='/service/:serviceId/' element={<ServicePage />} />
            <Route path='/trainings/cohort/:cohortId/apply/' element={<ApplicationPage />} />
            {/* dashboard */}
            {/* <Route path='/dashboard/me/' element={<ProfilePage />} /> */}
            <Route path='/dashboard/cohorts/:cohortId/applications/' element={<ProtectedRoute><CohortApplicationsPage /></ProtectedRoute>} />
            <Route path='/dashboard/cohorts/' element={<ProtectedRoute><CohortsPage /></ProtectedRoute>} />

          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </>
    </GoogleOAuthProvider>
  );
}

export default App;
